.t {
  transform: none;
}
[sub-menu] {
  display: block;
  position: sticky;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  padding-top: 3.75rem;
  width: 100%;
  background-color: #fff;
  transform: translateY(0rem);
  transition: all 0.6s ease;
  z-index: 2;
}
[sub-menu].scrolled {
  transform: translateY(-3.75rem);
}
[sub-menu].hidden {
  transform: translateY(-120%);
}
[sub-menu] ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  border-top: 1px solid #eee;
}
[sub-menu] ul a {
  position: relative;
  display: block;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  opacity: 0.5;
  padding-top: 1.125rem;
  padding-right: 0rem;
  padding-bottom: 1.125rem;
  padding-left: 0rem;
}
[sub-menu] ul a.router-link-active {
  opacity: 1;
}
[sub-menu] ul a.router-link-active:after {
  content: '';
  display: block;
  position: absolute;
  left: 0rem;
  bottom: 0rem;
  right: auto;
  top: auto;
  right: 0rem;
  height: 0.125rem;
  background-color: #000;
}
[sub-menu] ul img {
  display: none;
}
@media (min-width: 1280px) {
  [sub-menu] {
    padding-top: 5rem;
  }
  [sub-menu].scrolled {
    transform: translateY(-5rem);
  }
  [sub-menu].hidden {
    transform: translateY(-100%);
  }
  [sub-menu] ul {
    display: flex;
    justify-content: center;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
    border-top: 0;
  }
  [sub-menu] ul li {
    position: relative;
  }
  [sub-menu] ul li + li {
    margin-left: 3.5rem;
  }
  [sub-menu] ul a {
    display: block;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-top: 1.125rem;
    padding-right: 0rem;
    padding-bottom: 1.125rem;
    padding-left: 0rem;
    width: 9.25rem;
    opacity: 0.5;
  }
  [sub-menu] ul a:hover {
    opacity: 1;
  }
  [sub-menu] ul a.router-link-active {
    opacity: 1;
  }
  [sub-menu] ul a.router-link-active:after {
    content: '';
    display: block;
    position: absolute;
    left: 0.8125rem;
    bottom: 0rem;
    right: auto;
    top: auto;
    right: 0.8125rem;
    height: 0.125rem;
    background-color: #000;
  }
  [sub-menu] ul img {
    display: block;
    width: 3rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0.125rem;
    margin-left: auto;
  }
}
